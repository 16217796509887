import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import logo from "../prediceLogo.png";
import AuthService from "../services/AuthService";
import history from "./history";

const Login = props => {

    const { classes } = props;

    if (AuthService.isAuthenticated()) {
        history.push("/");
    }

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const handleChangeUserName = event => {
        setUserName(event.target.value);
    }

    const handleChangePassword = event => {
        setPassword(event.target.value);
    }

    const handleSubmit = e => {
        e.preventDefault();
        AuthService.setToken(AuthService.generateToken());
        if (userName === "patient") {
            history.push("/patient");
        } else if (userName === "recueil") {
            history.push("/collect");
        } else {
            history.push("/");
        }
    };

    return (
        <React.Fragment>
            <Grid container direction="column" justify="space-evenly" alignItems="center" className={classes.login}>
                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                    <Typography variant="h2" color="secondary" gutterBottom>
                        Authentification
                </Typography>
                    <Grid item>
                        <TextField
                            id="User"
                            variant="outlined"
                            label="Utilisateur"
                            fullWidth
                            value={userName}
                            onChange={handleChangeUserName}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="Password"
                            variant="outlined"
                            type="password"
                            label="Mot de passe"
                            value={password}
                            onChange={handleChangePassword}
                        //   helperText={errorMessage}
                        //   error={errorMessage !== ""}
                        //   inputProps={{ maxLength: 4 }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={handleSubmit}
                            disabled={!userName || !password}
                        >
                            Connexion
                                </Button>
                    </Grid>
                </Grid>
                <img src={logo} alt="Prédice" className={classes.logo} />
            </Grid>
        </React.Fragment>
    );
}

Login.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

const styles = () => ({
    logo: {
        height: "166px",
        width: "214px",
        borderRadius: "100px",
        margin: "20px",

    },
    login: {
        height: "100vh",
    },
});

export default withStyles(styles)(Login);
