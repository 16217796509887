import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Button, Grid, withStyles } from "@material-ui/core";
import HeaderPage from "../HeaderPage";
import MedicalConsentText from "../MedicalConsentText";
import ModalConfirmation from "../ModalConfirmation";

const NewMedicalConsent = props => {

    const { classes, closePatientDetails, handleMedicalConsentValidated } = props;

    const date = new Date();
    const day = (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate();
    const month = ((date.getMonth() + 1) < 10) ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1);
    const today = `${day}/${month}/${date.getFullYear()}`;

    const [openModal, setOpenModal] = useState(false);
    const [secondSignDone, setSecondSignDone] = useState(false);

    const handleModal = change => {
        setOpenModal(change);
    };

    const handleSecondSignature = () => {
        setSecondSignDone(!secondSignDone);
    };

    return (
        <Grid container direction="column" className={classes.consent}>
            <HeaderPage
                title={`Consentement médical - ${today}`}
                patient={"M. Paul TEST - 19/03/1950"}
                closePatientDetails={closePatientDetails}
            />
            <Grid container direction="column" justify="space-evenly" alignItems="flex-start" className={classes.calcHeight}>
                <MedicalConsentText date={today} patient={"M. Paul TEST - 19/03/1950"} secondSignDone={secondSignDone} handleSecondSignature={handleSecondSignature} />
                <Grid item container direction="row" justify="space-evenly" className={classes.padding}>
                    {!secondSignDone && (
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={handleSecondSignature}
                        >
                            Signer
                        </Button>
                    )}
                    {secondSignDone && (
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => handleModal(true)}
                        >
                            Valider
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        className={classes.red}
                        onClick={() => handleMedicalConsentValidated(true)}
                    >
                        Refuser
                    </Button>
                </Grid>
            </Grid>
            <ModalConfirmation open={openModal} handleModal={handleModal} handleMedicalConsentValidated={handleMedicalConsentValidated} />
        </Grid>
    );
}

NewMedicalConsent.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    closePatientDetails: PropTypes.func.isRequired,
    handleMedicalConsentValidated: PropTypes.func.isRequired,
};

const styles = () => ({
    consent: {
        height: "calc(100vh - 168px)",
        padding: "0 40px",
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb",
    },
    calcHeight: {
        height: "calc(100vh - 280px)",
    },
    padding: {
        padding: "15px 0",
    },
    red: {
        backgroundColor: "#ff4545",
        "&:hover": {
            backgroundColor: "#ff1717",
        },
        color: "white",
    },
});

export default withStyles(styles)(NewMedicalConsent);