import React, { useState } from 'react';
import SearchPatient from '../components/layout/SearchPatient';
import PatientDetails from './PatientDetails';

const AskConsent = () => {

    const [patientSelected, setPatientSelected] = useState(false);

    const handlechange = change => {
        setPatientSelected(change);
    }

    return (
        <React.Fragment>
            {!patientSelected ?
                <SearchPatient openPatientDetails={handlechange} /> :
                <PatientDetails closePatientDetails={handlechange} />}
        </React.Fragment>
    );
}

export default AskConsent;