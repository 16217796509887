import React from 'react';
import PropTypes from "prop-types";
import { MenuItem, TextField } from "@material-ui/core";


const CollectedChoice = props => {

    const { choice, setChoice } = props;

    const choices = [
      { value: 1, label: "Sur tablette" },
      { value: 2, label: "Sur device personnel du patient (envoi d'un email)" },
    ];

    const handleChangeChoice = event => {
        setChoice(event.target.value);
    }

    return (
      <TextField
        id="select-choice"
        select
        label="Mode de recueil"
        value={choice}
        fullWidth
        onChange={handleChangeChoice}
        margin="normal"
      >
        {choices.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  CollectedChoice.propTypes = {
    choice:PropTypes.number.isRequired,
    setChoice:PropTypes.func.isRequired,
};

  export default CollectedChoice;