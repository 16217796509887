import React, { useState, useEffect } from 'react';
import { Button, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography, TextField, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import PatientConsentsTable, { consentColumns } from '../PatientConsentsTable';
import AccessCodeModal from '../AccessCodeModal';
import ActionsMenu from '../ActionsMenu';
import ConsentService from '../../services/ConsentService';

const PATIENT_COLUMNS = [
    consentColumns.DATE,
    consentColumns.TYPE,
    consentColumns.ETAB,
    consentColumns.STATUS,
];

const ETAB_COLUMNS = [
    consentColumns.DATE,
    consentColumns.PATIENT,
    consentColumns.EMAIL,
    consentColumns.STATUS,
];

const ViewListing = props => {

    const { classes, patientSpace, showConsent } = props;

    const [patientId, setPatientId] = useState("");
    const [status, setStatus] = useState('all');
    const [anchorEl, setAnchorEl] = useState(null);
    const [showCode, setShowCode] = useState(false);
    const [consents, setConsents] = useState([]);
    const [currentConsent, setCurrentConsent] = useState(null);

    useEffect(() => {
        ConsentService.findAll().then(
            consents => setConsents(consents.filter(consent => {
                if (patientSpace) {
                    return consent.patient === "Paul Test"
                } 
                return consent.hospitalCenter === "CHRU Lille"
            })
        ));
    }, [patientSpace]);

    const columns = patientSpace ? PATIENT_COLUMNS : ETAB_COLUMNS;

    const handleFilter = event => {
        setStatus(event.target.value);
    }

    const handleChangePatientId = event => {
        setPatientId(event.target.value);
    }

    const openConsentActions = consent => event => {
        setCurrentConsent(consent);
        setAnchorEl(event.currentTarget);
    }
    const closeActions = () => {
        setAnchorEl(null);
    }

    const handleModal = change => {
        setShowCode(change);
        closeActions();
    }

    // const handleSubmit = e => {
    //     e.preventDefault();
    // };


    return (
        <Grid container direction="column" className={classes.consent}>
            <Typography variant="h5" color="secondary" className={classes.title}>
                {patientSpace ? "Mes consentements" : "Liste des consentements de l'établissement"}
            </Typography>
            <Grid container direction="row" justify="center">
                <Divider className={classes.divider} />
            </Grid>
            <Grid container direction="column" justify="space-evenly" alignItems="center" className={classes.calcHeight}>
                {!patientSpace && (
                    <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={2}>
                        <Grid item xs={7}>
                            <TextField
                                id="Patient"
                                variant="outlined"
                                label="Rechercher un patient (Nom, Prénom, IPP)"
                                fullWidth
                                value={patientId}
                                onChange={handleChangePatientId}
                                disabled
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                size="large"
                            // onClick={handleSubmit}
                            // disabled
                            >
                                Rechercher
                                </Button>
                        </Grid>
                    </Grid>
                )}
                <Grid className={classes.fullWidth}>
                    <FormControl component="fieldset" fullWidth disabled>
                        <RadioGroup
                            aria-label="status"
                            name="status"
                            value={status}
                            onChange={handleFilter}
                        >
                            <Grid container justify="space-evenly">
                                <FormControlLabel value="all" control={<Radio />} label="Tous" />
                                <FormControlLabel value="pending" control={<Radio />} label="En attente" />
                                <FormControlLabel value="accepted" control={<Radio />} label="Accepté" />
                                <FormControlLabel value="refused" control={<Radio />} label="Refusé" />
                                <FormControlLabel value="revoked" control={<Radio />} label="Révoqué" />
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid container justify="center" className={classes.overflow}>
                    <PatientConsentsTable
                        columns={columns}
                        consents={consents}
                        openConsentActions={openConsentActions} />
                </Grid>
            </Grid>
            <ActionsMenu
                anchorEl={anchorEl}
                closeActions={closeActions}
                showConsent={() => showConsent(currentConsent)}
                handleModal={handleModal} patientSpace={patientSpace}
                currentConsent={currentConsent}/>
            <AccessCodeModal
                open={showCode}
                handleModal={handleModal} modalTitle="Code patient" label="Code" value="35-789-45"/>
        </Grid>
    );
}

ViewListing.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    patientSpace: PropTypes.bool.isRequired,
    showConsent: PropTypes.func.isRequired,
};

const styles = () => ({
    consent: {
        height: "calc(100vh - 168px)",
        padding: "0 40px",
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb"
    },
    title: {
        padding: "20px 0 15px 0",
    },
    divider: {
        backgroundColor: "#4fbaa2",
        width: "100%",
    },
    calcHeight: {
        height: "calc(100vh - 250px)",
    },
    fullWidth: {
        width: "100%",
    },
    overflow: {
        height: "350px",
        overflow: "auto",
    }
});

export default withStyles(styles)(ViewListing);