import React, { useState, useEffect } from 'react';
import ViewListing from "../components/layout/ViewListing";
import PreviewConsent from "../components/layout/PreviewConsent";
import history from "./history";


const ViewConsents = () => {

    const [previewConsent, setPreviewConsent] = useState(false);
    const [patientSpace, setPatientSpace] = useState(false);
    const [currentConsent, setCurrentConsent] = useState(null);

    useEffect(() => {
        if (history.location.pathname === "/patient") {
            setPatientSpace(true);
        } else {
            setPatientSpace(false);
        };
    }, [patientSpace]);


    const showConsent = consent => {
        setCurrentConsent(consent)
        setPreviewConsent(true);
    }

    const hideConsent = () => {
        setPreviewConsent(false);
    }

    return (
        <React.Fragment>
            {!previewConsent ?
                <ViewListing patientSpace={patientSpace} showConsent={showConsent} />
                :
                <PreviewConsent patientSpace={patientSpace} consent={currentConsent} hideConsent={hideConsent} />}
        </React.Fragment>
    );
}

export default ViewConsents;