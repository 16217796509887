import React from 'react';
import PropTypes from "prop-types";
// @material-ui/core
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


const ConsentType = props => {

  const { type, setType } = props;

  const types = [
    "Consentement Prédice",
    "Consentement RGPD",
    "Consentement Chirurgie réfractive",
  ];

  const handleChangeType = event => {
    setType(event.target.value);
  }

  return (
    <Grid container wrap="wrap">
      <FormControl fullWidth>
        <InputLabel htmlFor="select-multiple-checkbox">Type de Consentement</InputLabel>
        <Select
          multiple
          value={type}
          onChange={handleChangeType}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={selected => selected.join(', ')}
        >
          {types.map(t => (
            <MenuItem key={t} value={t}>
              <Checkbox checked={type.indexOf(t) > -1} />
              <ListItemText primary={t} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

ConsentType.propTypes = {
  type: PropTypes.array.isRequired,
  setType: PropTypes.func.isRequired,
};

export default ConsentType;