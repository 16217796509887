import React from 'react';
import PropTypes from "prop-types";
import { Divider, Grid, IconButton, Typography, withStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

const HeaderPage = props => {

    const { classes, closePatientDetails, title, patient } = props;

    return (
        <React.Fragment>
            <Grid container direction="row" justify="space-between" alignItems="center" className={classes.title}>
                <Typography variant="h5" color="secondary" >
                    {title}
                </Typography>
                {patient ?
                    <Grid item>
                        <IconButton aria-label="Close" onClick={() => closePatientDetails(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid> :
                    null}
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="body1" color="primary">
                        {patient}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Divider className={classes.divider} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

HeaderPage.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    // closePatientDetails: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    patient: PropTypes.string.isRequired,
};

const styles = () => ({
    title: {
        padding: "20px 0 15px 0",
    },
    divider: {
        backgroundColor: "#4fbaa2",
    },
});

export default withStyles(styles)(HeaderPage);