import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import NavBar from "./NavBar";
import history from "../../routes/history";

const Layout = props => {

    const { classes, children } = props;

  const [consent, setConsent] = useState(0);

  const handleChange = (event, consent) => {
    setConsent(consent);
  }

  useEffect(() => {
    if (history.location.pathname !== "/patient" && history.location.pathname !== "/collect") {
      if (consent === 0) {
        history.push("/")
      } else if (consent === 1) {
        history.push("/view")
      }
    } 
    }, [consent]);

  return (
    <div className={classes.root}>
      <NavBar consent={consent} handleChange={handleChange}/>
      <main className={classes.content}>
        {children}
      </main>
    </div>
  );
}

Layout.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
};

const styles = () => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  content: {
    flexGrow: 1,
    padding: "24px",
    height: "calc(100vh - 64px)",
    overflow: "auto",
  },
});

export default withStyles(styles)(Layout);
