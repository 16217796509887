import React, { useState } from 'react';
import { Button, Grid, IconButton, Link, TextField, Typography, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import CloseIcon from '@material-ui/icons/Close';
import HeaderPage from "../HeaderPage"


const SearchPatient = props => {

    const { classes, openPatientDetails } = props;

    const [patientId, setPatientId] = useState("");
    const [search, setSearch] = useState(false);

    const handleChangePatientId = event => {
        setPatientId(event.target.value);
    }

    const handleSubmit = e => {
        e.preventDefault();
        setSearch(true);
    };

    const handleClose = () => {
        setPatientId("");
        setSearch(false);
    }

    return (
        <Grid container direction="column" className={classes.consent}>
            <HeaderPage title={"Demande de consentement"} patient={""} />
            <Grid container direction="column" justify="space-around" alignItems="flex-start" spacing={8} className={classes.calcHeight}>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs container direction="column" justify="center">
                        <Typography variant="h6" color="secondary">
                            Rechercher un patient
                        </Typography>
                        <TextField
                            id="Patient"
                            variant="outlined"
                            label="Identifiant Permanent du Patient"
                            // fullWidth
                            value={patientId}
                            onChange={handleChangePatientId}
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={handleSubmit}
                            disabled={!patientId}
                        >
                            Rechercher
                    </Button>
                    </Grid>
                    <Grid item xs={2} sm={5} md={6} />
                </Grid>
                {search ?
                    <Grid container>
                        <Grid item xs={1} />
                        <Grid item xs container direction="row" justify="space-between" alignItems="center" className={classes.border}>

                            <Grid item xs>
                                <Link
                                    component="button"
                                    variant="h5"
                                    color="secondary"
                                    underline="always"
                                    onClick={() => openPatientDetails(true)}
                                >
                                    M. Paul TEST - 19/03/1950
                        </Link>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton aria-label="Close" onClick={() => handleClose()}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} sm={2} />
                    </Grid> :
                    <Grid container className={classes.hidden} />}
            </Grid>
        </Grid>
    );
}

SearchPatient.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    openPatientDetails: PropTypes.func.isRequired,
};

const styles = () => ({
    consent: {
        height: "calc(100vh - 200px)",
        padding: "0 40px",
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb",
    },

    calcHeight: {
        height: "calc(100vh - 280px)",
    },
    hidden: {
        height: "114px",
    },
    border: {
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb",
        padding: "32px",
    }
});

export default withStyles(styles)(SearchPatient);