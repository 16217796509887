import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Typography, withStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ConsentText from "../ConsentText"
import { getColorByStatus } from '../PatientConsentsTable';
import ConsentService from '../../services/ConsentService';

const PreviewConsent = props => {

    const { classes, patientSpace, consent, hideConsent } = props;
    
    const [checkbox, setCheckbox] = useState({
        forgetRight: false,
        rectificationRight: false,
        readRight: false,
        portabilityRight: false,
        oppositionRight: false,
        limitationRight: false,
      });

    const handleAccept = () => {
        ConsentService.update({
            ...consent,
            status : "Accepté",
        }).then(hideConsent)
    }

    const handleRefuse = () => {
        ConsentService.update({
            ...consent,
            status : "Refusé",
        }).then(hideConsent)
    }

    const handleRevoke = () => {
        ConsentService.update({
            ...consent,
            status : (consent && consent.oppositionRight) ? "Révoqué" : "Accepté",
        }).then(hideConsent)
    }

    
      const handleChangeCheckbox = name => event => {
        setCheckbox({ ...checkbox, [name]: event.target.checked });
            consent[name] = event.target.checked
      };

    const showAccepted = patientSpace && (consent.status === "En attente");
    const showRefused = patientSpace && (consent.status === "En attente");
    const showRevoked = patientSpace && (consent.status === "Accepté" || consent.status === "Révoqué");

    return (
        <Grid container direction="column" className={classes.consent}>
            {!patientSpace && (
                <Grid container>
                    <Grid item className={classes.infos}>
                        <Typography variant="body1" color="primary">
                        {consent.patient}
                    </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid container direction="row" justify="space-between" alignItems="center" className={classes.title}>
                <Grid item xs={6} container direction="column" justify="center" alignItems="flex-start">
                    {patientSpace && (
                        <Typography variant="h5" color="secondary" >
                            Mes consentements
                </Typography>
                    )}
                    <Typography variant="h6" color="secondary" >
                        {consent.type}
                    </Typography>
                    <Typography variant="h6" color="secondary" >
                        {`Envoyé le ${consent.date} - ${consent.hospitalCenter}`}
                    </Typography>
                </Grid>
                <Typography variant="h6" color="secondary" style={{color : getColorByStatus(consent.status)}} >
                    {consent.status}
                </Typography>
                <Grid item>
                    <IconButton aria-label="Close" onClick={hideConsent}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container alignItems="center">
                <Grid item xs>
                    <Divider className={classes.divider} />
                </Grid>
            </Grid>
            <Grid container direction="column" justify="space-evenly" alignItems="flex-start" className={classes.calcHeight}>
                <ConsentText />
                <Grid container direction="row" justify="space-evenly">
                    {showAccepted && (
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={handleAccept}
                        >
                            Accepter
                        </Button>
                    )}
                    {showRefused && (
                        <Button
                            variant="outlined"
                            size="large"
                            className={classes.red}
                            onClick={handleRefuse}

                        >
                            Refuser
                        </Button>
                    )}
                    {showRevoked && (
                        <Grid container direction="column" alignItems="center">
                            <Grid item container justify="space-around" className={classes.root}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={consent.forgetRight} onChange={handleChangeCheckbox('forgetRight')} value="forgetRight" />}
                                            label="Droit à l'oubli"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={consent.rectificationRight} onChange={handleChangeCheckbox('rectificationRight')} value="rectificationRight" />}
                                            label="Droit à la rectification des données"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={consent.readRight} onChange={handleChangeCheckbox('readRight')} value="readRight" />}
                                            label="Droit à la lecture des données"
                                        />
                                    </FormGroup>
                                </FormControl>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={consent.portabilityRight} onChange={handleChangeCheckbox('portabilityRight')} value="portabilityRight" />}
                                            label="Droit à la portabilité des données"
                                            />
                                    {/* </FormGroup>
                                </FormControl>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup> */}
                                        <FormControlLabel
                                            control={<Checkbox checked={consent.oppositionRight} onChange={handleChangeCheckbox('oppositionRight')} value="oppositionRight" />}
                                            label="Droit à l'opposition"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={consent.limitationRight} onChange={handleChangeCheckbox('limitationRight')} value="limitationRight" />}
                                            label="Droit à la limitation du traitement"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                onClick={handleRevoke}
                            >
                                Valider
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

PreviewConsent.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    patientSpace: PropTypes.bool.isRequired,
    hideConsent: PropTypes.func.isRequired,
};

const styles = () => ({
    consent: {
        height: "calc(100vh - 168px)",
        padding: "0 40px",
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb",
    },
    infos: {
        marginTop: "-12px",
        backgroundColor: "white",
    },
    title: {
        padding: "20px 0 15px 0",
    },
    divider: {
        backgroundColor: "#4fbaa2",
    },
    calcHeight: {
        height: "calc(100vh - 340px)",
    },
    red: {
        backgroundColor: "#ff4545",
        "&:hover": {
            backgroundColor: "#ff1717",
        },
        color: "white",
    },
    root: {
        display: 'flex',
      },
});

export default withStyles(styles)(PreviewConsent);