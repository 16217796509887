import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Box, Grid, TextField, Typography, withStyles } from "@material-ui/core";
import signature2 from "../signature2.png";
import signature3 from "../signature3.png";

const MedicalConsentText = props => {

    const { classes, date, patient, secondSignDone, handleSecondSignature } = props;

    const [restriction, setRestriction] = useState("");

    const handleChangeRestriction = event => {
        setRestriction(event.target.value);
    }


    return (
        <Grid container direction="row" justify="center" className={classes.text}>
            <Grid item>
                <Typography variant="body1" align="center" gutterBottom className={classes.bold}>
                    <br /> Consentement préalable à une opération de chirurgie réfractive
                    </Typography>
            </Grid>
            <Grid item>
                <p>Identification : {patient}</p>
                <p>Au cours de la consultation du : 15/07/2019</p>
                <p>
                    Le docteur Actidoc Werquin m’a apporté les informations liées à mon état visuel et à l’intervention envisagée.
                </p>
                <p>
                    Des documents spécifiques d’information m’ont été transmis.
                </p>
                <p>
                    Il m’a été en particulier expliqué :
                </p>
                <ul>
                    <li>Les raisons qui conduisent à la préconisation chirurgicale.</li>
                    <li>Les diverses alternatives à cette méthode.</li>
                    <li>
                        Les bénéfices attendus et les inconvénients et risques de toutes les options concernant mon cas.
                        J’ai compris que le rapport entre les bénéfices escomptés et les risques et inconvénients
                        n’exclut pas la possibilité d’effets nuisibles pour la capacité visuelle.
                    </li>
                </ul>
                <p>
                    Dans le cadre de ces entretiens, j’ai pu poser les questions que j’ai jugées utiles et j’ai compris les réponses fournies.
                </p>
                <p>
                    J’ai disposé du temps de réflexion nécessaire avant ma décision de me faire opérer.
                    J’ai compris que je devais prendre connaissance des divers moyens d’information confiés ou proposés.
                </p>
                <p>
                    J’ai compris et j’accepte par anticipation que la planification de l’opération puisse être modifiée
                    voire interrompue dans mon intérêt si un événement médical,
                    technique ou méthodologique inattendu vient perturber le déroulement prévu.
                </p>
                <p>
                    J’accepte aussi une intervention ou une action médicale supplémentaire réparatrice
                    ou de sauvegarde si elle s’avère nécessaire et m’est à nouveau expliqué.
                </p>
                <p>
                    Je sais que les protocoles les plus appropriés seront employés pour prévenir la douleur
                    et l’anxiété dans la recherche de mon bien-être durant mon traitement.
                </p>
                <p>
                    J’ai compris que je devais respecter les agendas post-opératoires ainsi que les conseils de suivi.
                     Je sais que je dois suivre le traitement ordonné sur la prescription post-opératoire et que les soins peuvent parfois être longs.
                </p>
                <p>
                    Un devis m’a été donné. J’ai compris que la chirurgie réfractive n’ouvrait pas les droits
                    à remboursement de l’Assurance-Maladie française. Je fais mon affaire des démarches possibles
                    auprès des organismes d’assurances personnelles.
                </p>
                <p>
                    J’ai donné mon accord pour que cette intervention soit réalisée selon les modalités envisagées.
                </p>
                <Grid container alignItems="center">
                    <Grid item xs={6}>
                        <p>
                            Je souhaite éventuellement apporter la restriction suivante :
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="Restriction"
                            variant="outlined"
                            multiline
                            fullWidth
                            value={restriction}
                            onChange={handleChangeRestriction}
                            margin="normal"
                        />
                    </Grid>
                </Grid>
                <p>
                    J’autorise que l’intervention soit éventuellement filmée.
                </p>
                <p>
                    J’autorise tout prélèvement nécessaire médicalement.
                </p>
                {date && (<p>Fait à Lille, le {date},</p>)}
            </Grid>
            {date && (
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item xs md={5} onClick={handleSecondSignature}>
                        <Box pt={1} pb={secondSignDone ? 5.5 : 14.5} pl={1} border={1} borderRadius={2}>
                            <Grid container direction="column">
                                Signature du patient,
                                {secondSignDone && (<img src={signature3} alt="Signature" className={classes.logo} />)}
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs md={5}>
                        <Box  pt={1} pl={1} border={1} borderRadius={2}>
                            <Grid container direction="column">
                                Dr Actidoc Werquin,
                                <img src={signature2} alt="Signature" className={classes.logo} />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

MedicalConsentText.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    date: PropTypes.string.isRequired,
};

const styles = () => ({
    text: {
        flex: "1",
        overflow: "auto",
    },
    bold: {
        fontWeight: "bold",
    },
    logo: {
        width: "279px",
    },
});

export default withStyles(styles)(MedicalConsentText);