import React from 'react';
import PropTypes from "prop-types";
import { Button, Grid, Typography, withStyles } from "@material-ui/core";
import HeaderPage from "../HeaderPage"

const AcceptedOrRefusedConsent = props => {

    const { classes, closeAll } = props;

    return (
        <Grid container direction="column" className={classes.consent}>
            <HeaderPage
                title={"Recueil de consentement"}
                patient={"M. Paul TEST - 19/03/1950"}
                closePatientDetails={closeAll}
            />
            <Grid container justify="center" alignItems="center" className={classes.height}>
                <Grid item xs={false} md={2} />
                <Grid item xs={12} md={8} container direction="column" justify="space-between" alignItems="center" className={classes.border}>
                    <Typography variant="body1">
                    Le recueil de vos consentements a bien été effectué. <br/>
                    Vous pouvez à tout moment les visualiser et exercer vos droits depuis votre espace personnel de l’établissement
                    ou en vous rapprochant de votre interlocuteur administratif.
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => closeAll(false)}
                    >
                        Fermer
                    </Button>
                </Grid>
                <Grid item xs={false} md={2} />
            </Grid>
        </Grid>
    );
}

AcceptedOrRefusedConsent.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    closeAll: PropTypes.func.isRequired,
};

const styles = () => ({
    consent: {
        height: "calc(100vh - 168px)",
        padding: "0 40px",
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb",
    },
    height: {
        height: "65%",
    },
    border: {
        height: "230px",
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb",
        padding: "32px",
    },
});

export default withStyles(styles)(AcceptedOrRefusedConsent);