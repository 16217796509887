import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Button, Grid, TextField, Typography, withStyles } from "@material-ui/core";


const CollectAccess = props => {

    const { classes, getAuthorized } = props;

    // const [patientId, setPatientId] = useState("");
    const [code, setCode] = useState("");

    // const handleChangePatientId = event => {
    //     setPatientId(event.target.value);
    // }

    const handleChangeCode = event => {
        setCode(event.target.value);
    }

    return (
        <Grid container direction="column" className={classes.consent}>
            {/* <Typography variant="h5" color="secondary" className={classes.title}>
                Recueil du consentement
            </Typography> */}
            <Grid container justify="center" alignItems="center" className={classes.height}>
                <Grid item xs={false} md={2} />
                <Grid item xs={12} md={8} className={classes.border}>
                    <Grid container>
                        <Grid item className={classes.infos}>
                            <Typography variant="body1" color="primary">
                                Connexion
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" justify="space-evenly" alignItems="flex-start" spacing={2}>
                        {/* <Grid container justify="flex-start" alignItems="center">
                            <Grid item xs={3}>
                                <Typography variant="body1" color="primary">
                                    IPP
                            </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="User"
                                    variant="outlined"
                                    label="Identifiant Permanent du Patient"
                                    fullWidth
                                    value={patientId}
                                    onChange={handleChangePatientId}
                                    margin="normal"
                                />
                            </Grid>
                        </Grid> */}
                        <Grid container justify="flex-start" alignItems="center">
                            <Grid item xs>
                                <Typography variant="body1" color="primary">
                                    Code d'accès au consentement patient :
                            </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" alignItems="center">
                            <Grid item xs={3} />
                            <Grid item xs={6}>
                                <TextField
                                    id="Code"
                                    variant="outlined"
                                    label="Code"
                                    fullWidth
                                    value={code}
                                    onChange={handleChangeCode}
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>
                        <Grid container justify="center" alignItems="center">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() => getAuthorized(true)}
                                    disabled={!code}
                                >
                                    Connexion
                            </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={false} md={2} />
            </Grid>
        </Grid>
    );
}

CollectAccess.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    getAuthorized: PropTypes.func.isRequired,
};

const styles = () => ({
    consent: {
        height: "calc(100vh - 168px)",
        padding: "0 40px",
    },
    title: {
        padding: "20px 0 15px 0",
    },
    height: {
        height: "60%",
    },
    infos: {
        marginTop: "-45px",
        backgroundColor: "white",
    },
    border: {
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb",
        padding: "32px",
    },
});

export default withStyles(styles)(CollectAccess);