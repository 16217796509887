import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography, withStyles } from "@material-ui/core";
import HeaderPage from "../HeaderPage";
import ConsentText from "../ConsentText"

const NewCollectConsent = props => {

    const { classes, closePatientDetails, handleConsentValidated } = props;

    const date = new Date();
    const day = (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate();
    const month = ((date.getMonth() + 1) < 10) ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1);
    const today = `${day}/${month}/${date.getFullYear()}`;

    const [shareStatus, setShareStatus] = useState("");
    const [useStatus, setUseStatus] = useState("");
    const [treatStatus, setTreatStatus] = useState("");
    const [firstSignDone, setFirstSignDone] = useState(false);

    const handleShareStatus = event => {
        setShareStatus(event.target.value);
    };

    const handleUseStatus = event => {
        setUseStatus(event.target.value);
    };

    const handleTreatStatus = event => {
        setTreatStatus(event.target.value);
    };

    const handleFirstSignature = () => {
        setFirstSignDone(!firstSignDone);
    };

    return (
        <Grid container direction="column" className={classes.consent}>
            <HeaderPage
                title={`Consentement Prédice - RGPD - ${today}`}
                patient={"M. Paul TEST - 19/03/1950"}
                closePatientDetails={closePatientDetails}
            />
            <Grid container direction="column" justify="space-evenly" alignItems="flex-start" className={classes.calcHeight}>
                <ConsentText date={today} firstSignDone={firstSignDone} handleFirstSignature={handleFirstSignature}/>
                <div className={classes.padding}>
                    <Grid item container justify="space-between">
                        <Grid item xs={6} md={8}>
                            <Typography>
                                J'autorise Prédice à partager mes données de santé avec d'autres établissements de santé de la région.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="status"
                                    name="status"
                                    value={shareStatus}
                                    onChange={handleShareStatus}
                                >
                                    <Grid container>
                                        <FormControlLabel value="yes" control={<Radio />} label="Oui" />
                                        <FormControlLabel value="no" control={<Radio />} label="Non" />
                                        <FormControlLabel value="pending" control={<Radio />} label="En attente" />
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container justify="space-between">
                        <Grid item xs={6} md={8}>
                            <Typography>
                                J'autorise Prédice à utiliser mon adresse email pour me permettre de recevoir des notifications par email.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="status"
                                    name="status"
                                    value={useStatus}
                                    onChange={handleUseStatus}
                                >
                                    <Grid container>
                                        <FormControlLabel value="yes" control={<Radio />} label="Oui" />
                                        <FormControlLabel value="no" control={<Radio />} label="Non" />
                                        <FormControlLabel value="pending" control={<Radio />} label="En attente" />
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container justify="space-between">
                        <Grid item xs={6} md={8}>
                            <Typography>
                                J'autorise Prédice à traiter mes données personnelles, y compris mes données de santé aux fins de réalisation d'analyses statistiques.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="status"
                                    name="status"
                                    value={treatStatus}
                                    onChange={handleTreatStatus}
                                >
                                    <Grid container>
                                        <FormControlLabel value="yes" control={<Radio />} label="Oui" />
                                        <FormControlLabel value="no" control={<Radio />} label="Non" />
                                        <FormControlLabel value="pending" control={<Radio />} label="En attente" />
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <Grid item container direction="row" justify="center">
                    {!firstSignDone && (
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={handleFirstSignature}
                        >
                            Signer
                        </Button>
                    )}
                    {firstSignDone && (
                        <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => handleConsentValidated(true)}
                        disabled={!shareStatus || !useStatus || !treatStatus}
                    >
                            Valider
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

NewCollectConsent.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    closePatientDetails: PropTypes.func.isRequired,
    handleConsentValidated: PropTypes.func.isRequired,
};

const styles = () => ({
    consent: {
        height: "calc(100vh - 168px)",
        padding: "0 40px",
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb",
    },
    calcHeight: {
        height: "calc(100vh - 280px)",
    },
    padding: {
        padding: "15px 0",
    },
    red: {
        backgroundColor: "#ff4545",
        "&:hover": {
            backgroundColor: "#ff1717",
        },
        color: "white",
    },
});

export default withStyles(styles)(NewCollectConsent);