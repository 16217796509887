import React from 'react';
import PropTypes from "prop-types";
import { Menu, MenuItem } from '@material-ui/core';


const ActionsMenu = props => {

  const { anchorEl, closeActions, showConsent, handleModal, patientSpace, currentConsent } = props;

  const showAccessCode = !patientSpace && anchorEl && (currentConsent.status === "En attente")
  const actionLabel = (patientSpace && anchorEl && (currentConsent.status === "Accepté" || currentConsent.status === "Révoqué")) ? "Exercer mes droits" : "Visualiser le consentement" 
  
  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeActions}
      >
        <MenuItem onClick={() => showConsent()}>{actionLabel}</MenuItem>
        {showAccessCode && (
          <MenuItem onClick={() => handleModal(true)}>Afficher le code d'accès</MenuItem>)}
      </Menu>
    </div>
  );
}

ActionsMenu.propTypes = {
  // anchorEl: PropTypes.element.isRequired,
  closeActions: PropTypes.func.isRequired,
  showConsent: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired,
  patientSpace: PropTypes.bool.isRequired,
};

export default ActionsMenu;