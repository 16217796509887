import React, { useState, useEffect, Fragment } from "react";
import { Router, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles, MuiThemeProvider } from "@material-ui/core";
import theme from "./components/Theme";
import AuthService from "./services/AuthService";
import history from "./routes/history";
import Login from "./routes/Login";
import AskConsent from "./routes/AskConsent";
import ViewConsents from "./routes/ViewConsents";
import CollectOfConsent from "./routes/CollectOfConsent";
import Layout from "./components/layout/Layout";
import PrivateRoute from "./routes/PrivateRoute";



 const App = props => {

  const { classes } = props;

  const [isAuthenticated, setIsAuthenticated] = useState(AuthService.isAuthenticated(),);

  useEffect(() => {
    history.listen(() => {
      // Check if user is still authenticated when location is updated and display connected layout or nothing
      setIsAuthenticated(AuthService.isAuthenticated());
    });
  }, []);

  const Wrapper = isAuthenticated ? Layout : Fragment;

    return (
      <div className={classes.app}>
        <Router history={history}>
          <MuiThemeProvider theme={theme}>
          <Wrapper>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={AskConsent} />
            <PrivateRoute exact path="/view" component={ViewConsents} />
            <PrivateRoute exact path="/collect" component={CollectOfConsent} />
            <PrivateRoute exact path="/patient" component={ViewConsents} />
          </Wrapper>
          </MuiThemeProvider>
        </Router>
      </div>
    );
}

App.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const styles = () => ({
  app: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});


export default withStyles(styles)(App);
