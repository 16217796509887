import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Box, Button, Divider, Grid, IconButton, Typography, withStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CollectedChoice from '../CollectedChoice';
import ConsentType from '../ConsentType';
import PatientConsentsTable, { consentColumns } from '../PatientConsentsTable';
import ConsentService from '../../services/ConsentService';

const PATIENT_COLUMNS = [
    consentColumns.DATE,
    consentColumns.TYPE,
    consentColumns.ETAB,
    consentColumns.STATUS,
];

const PatientDetails = props => {

    const { classes, closePatientDetails, handlePages, choice, setChoice, type, setType } = props;

    const [consents, setConsents] = useState([]);

    useEffect(() => {
        ConsentService.findAll().then(
            consents => setConsents(consents.filter(consent => 
                consent.index === 8 || consent.index === 9 || consent.index === 10 || consent.index === 11)
            ));
    }, []);

    const addNewConsent = () => {
        ConsentService.create({ patient: "Paul Test", email: "paul.test@gmail.com", type: "Consentement Prédice - Partage", hospitalCenter: "CHRU Lille", status: "En attente" })
        ConsentService.create({ patient: "Paul Test", email: "paul.test@gmail.com", type: "Consentement Prédice - Notification", hospitalCenter: "CHRU Lille", status: "En attente" })
        ConsentService.create({ patient: "Paul Test", email: "paul.test@gmail.com", type: "Consentement Prédice - Analyse statistique", hospitalCenter: "CHRU Lille", status: "En attente" })
        ConsentService.create({ patient: "Paul Test", email: "paul.test@gmail.com", type: "Consentement médical", hospitalCenter: "CHRU Lille", status: "En attente" })
        handlePages("sent")
    }

    return (
        <Grid container direction="column" className={classes.consent}>
            <Grid container>
                <Grid item className={classes.infos}>
                    <Typography variant="body1" color="primary">
                        M. Paul TEST - 19/03/1950
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction="column" justify="space-around" alignItems="flex-start" className={classes.calcHeight}>
                <Grid container direction="row" justify="space-between" alignItems="center" >
                    <Typography variant="h5" color="secondary" className={classes.title}>
                        Liste des consentements existants
                </Typography>
                    <Grid item>
                        <IconButton aria-label="Close" onClick={() => closePatientDetails(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>

                    <Grid container justify="center" className={classes.table}>
                        {consents.length ?
                            <PatientConsentsTable columns={PATIENT_COLUMNS} consents={consents} />
                            :
                            <Box textAlign="center" fontStyle="oblique" pt={4}>Aucun consentement existant</Box>
                        }
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center">
                    <Divider className={classes.divider} />
                </Grid>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={2}>
                    <Typography variant="h5" color="secondary" gutterBottom>
                        Demande de consentement
                </Typography>
                    <Grid item container direction="row" justify="space-evenly" alignItems="center" spacing={10}>
                        <Grid item xs={4}>
                            <Typography variant="h6" color="secondary">
                                Type de consentement :
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <ConsentType type={type} setType={setType} />
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" justify="space-evenly" alignItems="center" spacing={10}>
                        <Grid item xs={4}>
                            <Typography variant="h6" color="secondary">
                                Mode de recueil :
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <CollectedChoice choice={choice} setChoice={setChoice} />
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" justify="space-evenly" alignItems="center" spacing={10}>
                        <Grid item xs={4} />
                        <Grid item xs={7}>
                            <Button variant="contained" color="secondary" size="large" onClick={addNewConsent} disabled={!choice}>
                                Envoyer
                                </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

PatientDetails.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    closePatientDetails: PropTypes.func.isRequired,
    handlePages: PropTypes.func.isRequired,
    choice: PropTypes.number.isRequired,
    setChoice: PropTypes.func.isRequired,
    type: PropTypes.array.isRequired,
    setType: PropTypes.func.isRequired,
};

const styles = () => ({
    consent: {
        height: "calc(100vh - 168px)",
        padding: "0 40px",
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb"
    },
    infos: {
        marginTop: "-12px",
        backgroundColor: "white",
    },
    calcHeight: {
        height: "calc(100vh - 190px)",
    },
    table: {
        maxHeight : "150px",
        overflow : "auto",
    },
    divider: {
        margin: "16px",
        backgroundColor: "#4fbaa2",
        width: "300px",
    },
    title: {
        padding: "20px 0 15px 0",
    },
});

export default withStyles(styles)(PatientDetails);