
const STORAGE_KEY = "consents";

let consents = [
    { index: 7, date: "21/07/2019", patient: "Paul Test", email: "paul.test@gmail.com", type: "Consentement Prédice - Analyse statistique", hospitalCenter: "CH Valenciennes", status: "Accepté" },
    { index: 6, date: "09/07/2019", patient: "Giacomo Guilizzoni", email: "giacomo.g@gmail.com", type: "Consentement Prédice - RGPD", hospitalCenter: "CHRU Lille", status: "Révoqué", oppositionRight: true },
    { index: 5, date: "15/06/2019", patient: "Paul Test", email: "paul.test@gmail.com", type: "Consentement Prédice - Notification", hospitalCenter: "CH Valenciennes", status: "Refusé" },
    { index: 4, date: "02/05/2019", patient: "Paul Test", email: "paul.test@gmail.com", type: "Consentement Prédice - Partage", hospitalCenter: "CHRU Lille", status: "Accepté" },
    { index: 3, date: "25/04/2019", patient: "Marco Botton", email: "m.botton@gmail.com", type: "Consentement Prédice - RGPD", hospitalCenter: "CHRU Lille", status: "Refusé" },
    { index: 2, date: "21/04/2019", patient: "Paul Test", email: "paul.test@gmail.com", type: "Consentement Prédice - Partage", hospitalCenter: "CHU Amiens", status: "Révoqué", oppositionRight: true },
    { index: 1, date: "08/04/2019", patient: "Gilles Lafouche", email: "-", type: "Consentement Prédice - RGPD", hospitalCenter: "CHRU Lille", status: "Accepté" },
];


try {
    const consentsFromStorage = JSON.parse(sessionStorage.getItem(STORAGE_KEY))
    if (consentsFromStorage) {
        consents = consentsFromStorage;
    }
} catch (e) {
}

export const saveIntoSessionStorage = () => {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(consents));
}


export default class ConsentService {

    static findAll = async () => {
        return consents.map(c => ({...c}));
    }

    static findById = async (index) => {
        return consents.find(consent => consent.index === index);
    }

    static create = async (consent) => {
        const date = new Date();
        const day = (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate();
        const month = ((date.getMonth() + 1) < 10) ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1);
        const newConsent = {
            ...consent,
            index: consents.length + 1,
            date: `${day}/${month}/${date.getFullYear()}`,
        }
        consents.unshift(newConsent);
        saveIntoSessionStorage();
        return newConsent;
    }

    static update = async (updatedConsent) => {
        consents = consents.map(consent => {
            if (consent.index !== updatedConsent.index) {
                return consent;
            }
            return updatedConsent;
        })
        saveIntoSessionStorage();
    }
}
