import React from 'react';
import PropTypes from "prop-types";
import { Box, Button, Grid, Typography, withStyles } from "@material-ui/core";
import HeaderPage from "../HeaderPage"

const ConsentSent = props => {

    const { classes, closePatientDetails, handlePages, choice } = props;


    return (
        <Grid container direction="column" className={classes.consent}>
            <HeaderPage
                title={"Demande de consentement"}
                patient={"M. Paul TEST - 19/03/1950"}
                closePatientDetails={closePatientDetails}
            />
            <Grid container direction="column" justify="space-evenly" alignItems="center" className={classes.calcHeight}>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={false} md={2} lg={3} />
                    <Grid item xs={12} md={8} lg={6} className={classes.border}>
                        {choice === 1 ?
                            <Typography variant="body1">
                                Les consentements du patient Paul Test ont été envoyés sur tablette <br/>
                                Pour visualiser les consentements et les accepter / les refuser, veuillez renseigner le code généré ci-dessous depuis la tablette :
                        </Typography>
                            :
                            <Typography variant="body1">
                                Le consentement du patient Paul Test a été envoyé sur par email à l'adresse suivante :
                        </Typography>
                        }
                    </Grid>
                    <Grid item xs={false} md={2} lg={3} />
                </Grid>
                <Box fontSize="h6.fontSize" textAlign="center" border={1} borderRadius={8} p={2}>{(choice === 1) ? "35-789-45" : "paul.test@gmail.com"}</Box>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => handlePages("listing")}
                >
                    Retour
                    </Button>
            </Grid>
        </Grid>
    );
}

ConsentSent.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    closePatientDetails: PropTypes.func.isRequired,
    handlePages: PropTypes.func.isRequired,
    choice: PropTypes.number.isRequired,
};

const styles = () => ({
    consent: {
        height: "calc(100vh - 168px)",
        padding: "0 40px",
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb",
    },
    calcHeight: {
        height: "calc(100vh - 280px)",
    },
    border: {
        width: "75%",
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb",
        padding: "32px",
    },
});

export default withStyles(styles)(ConsentSent);