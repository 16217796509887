import React, { useState } from 'react';
import PropTypes from "prop-types";
import ListingConsents from '../components/layout/ListingConsents';
import NewAskConsent from '../components/layout/NewAskConsent';
import ConsentSent from '../components/layout/ConsentSent';

const PatientDetails = props => {

    const { closePatientDetails } = props;
        
    const [consentPage, setConsentPage] = useState("listing");
    const [choice, setChoice] = useState("");
    const [type, setType] = useState(["Consentement Prédice", "Consentement Chirurgie réfractive"]);
        
    const handlePages = change => {
    setConsentPage(change);
    };
        

    return (
        <React.Fragment>
            {(consentPage === "listing") && (
                <ListingConsents
                    closePatientDetails={closePatientDetails}
                    handlePages={handlePages} choice={choice}
                    setChoice={setChoice}
                    type={type}
                    setType={setType}/>
            )}
            {(consentPage === "newAsk") && (
                <NewAskConsent closePatientDetails={closePatientDetails} handlePages={handlePages} choice={choice}/>
            )}
            {(consentPage === "sent") && (
                <ConsentSent closePatientDetails={closePatientDetails} handlePages={handlePages} choice={choice}/>
            )}
        </React.Fragment>
    );
}

PatientDetails.propTypes = {
    closePatientDetails: PropTypes.func.isRequired,
};


export default PatientDetails;