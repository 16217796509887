import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Grid, IconButton, Paper, Tab, Tabs, Toolbar, Typography, withStyles
} from '@material-ui/core';
import PropTypes from "prop-types";
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import AuthService from "../../services/AuthService";
import history from "../../routes/history";

const NavBar = props => {
  const { classes, consent, handleChange } = props;

  const [patientSpace, setPatientSpace] = useState(false);
  const [collectSpace, setCollectSpace] = useState(false);

  const handleClickLogout = e => {
    e.preventDefault();
    AuthService.logout();
    history.push("/login");
  };

  useEffect(() => {
    if (history.location.pathname === "/patient") {
      setPatientSpace(true);
    } else if (history.location.pathname === "/collect") {
      setCollectSpace(true);
    }
  }, [patientSpace]);
  

  const handleChangePortail = (change) => {
    if (!patientSpace) {
      if (collectSpace) {
        history.push("/");
        setCollectSpace(change);
      } else if (!collectSpace) {
        history.push("/collect");
        setCollectSpace(change);
      } 
    }
  }
  
  return (
    <div className={classes.root}>
      <AppBar color="secondary" position="static">
        <Toolbar>
          {!collectSpace && (
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={3}>
                <Typography variant="h6" onClick={() => handleChangePortail(true)}>
                  {!patientSpace ? "Prédice" : "Mon espace patient"}
                </Typography>
              </Grid>
              {!patientSpace && (
                <Grid item xs>
                  <Paper>
                    <Tabs
                      value={consent}
                      onChange={handleChange}
                      indicatorColor="secondary"
                      textColor="secondary"
                      centered
                    >
                      <Tab label="Demander" wrapped={false} />
                      <Tab label="Visualiser" wrapped={false} />
                    </Tabs>
                  </Paper>
                </Grid>
              )}
              <Grid item xs={3} container justify={patientSpace ? "space-between" : "flex-end"} alignItems="center">
                {patientSpace && (
                  <Typography variant="h6"> M. Paul TEST </Typography>
                )}
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleClickLogout}>
                  <PowerSettingsNew />
                </IconButton>
              </Grid>
            </Grid>
          )}
          {collectSpace && (
            <Grid container justify="center">
              <Typography variant="h5" onClick={() => handleChangePortail(false)}>
                Recueil du consentement
              </Typography>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

NavBar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  consent: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: "8px",
  },
});

export default withStyles(styles)(NavBar);