import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Button, Grid, withStyles } from "@material-ui/core";
import HeaderPage from "../HeaderPage"
import ConsentText from "../ConsentText"
import ConsentService from '../../services/ConsentService'; 
import AccessCodeModal from '../AccessCodeModal';

const NewAskConsent = props => {

    const { classes, handlePages, closePatientDetails, choice} = props;

    const [printMail, setPrintMail] = useState(false);

    const addNewConsent = () => {
    ConsentService.create({ patient: "Paul Test", email: "paul.test@gmail.com", type: "Consentement Prédice - RGPD", hospitalCenter: "CHRU Lille", status: "En attente" })
    handlePages("sent")
    }

    const sendEmail = (change) => {
        setPrintMail(change)
    }

    return (
        <Grid container direction="column" className={classes.consent}>
            <HeaderPage
                title="Demande de consentement" 
                patient={"M. Paul TEST - 19/03/1950"}
                closePatientDetails={closePatientDetails}
                />
            <Grid container direction="column" justify="space-evenly" alignItems="flex-start" className={classes.calcHeight}>
                <ConsentText/>
                <Grid container direction="row" justify="space-evenly">
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={() => handlePages("listing")}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={(choice===1) ? addNewConsent : () => sendEmail(true)}
                    >
                        Envoyer
                    </Button>
                </Grid>
            </Grid>
            <AccessCodeModal open={printMail} handleModal={sendEmail} label="Email" modalTitle="Adresse email du patient" actionButton="Envoyer" addNewConsent={addNewConsent}/>
        </Grid>
    );
}

NewAskConsent.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    handlePages: PropTypes.func.isRequired,
    closePatientDetails: PropTypes.func.isRequired,
    choice: PropTypes.number.isRequired,
};

const styles = () => ({
    consent: {
        height: "calc(100vh - 168px)",
        padding: "0 40px",
        border: "1px solid #4fbaa2",
        borderRadius: "10px",
        boxShadow: "2px 3px 3px 1px #7fccbb",
    },
    calcHeight: {
        height: "calc(100vh - 280px)",
    },
    text: {
        height: "78%",
        overflow: "auto",
    },
    bold: {
        fontWeight: "bold",
    },
});

export default withStyles(styles)(NewAskConsent);