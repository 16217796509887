import jwt from "jsonwebtoken";
import config from "../config";

const { JWT_TOKEN, JWT_SECRET } = config;

const authService = {
  generateToken: () => {
    return jwt.sign({}, JWT_SECRET, {
      expiresIn: 60 * 60 * 24, // expires in 24 hours
    });
  },
  isAuthenticated: () => {
    // return jwt.verify(
    //   localStorage.getItem(JWT_TOKEN),
    //   JWT_SECRET,
    //   () => {
    //     return true;
    //   },
    // );
    return localStorage.getItem(JWT_TOKEN) != null;

  },
  setToken: accessToken => {
    localStorage.setItem(JWT_TOKEN, accessToken);
  },
  logout() {
    localStorage.removeItem(JWT_TOKEN);
  },
};

export default authService;
