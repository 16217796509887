import React, { useState } from 'react';
import CollectAccess from '../components/layout/CollectAccess';
import NewCollectConsent from '../components/layout/NewCollectConsent';
import NewMedicalConsent from '../components/layout/NewMedicalConsent';
import AcceptedOrRefusedConsent from '../components/layout/AcceptedOrRefusedConsent';

const CollectOfConsent = () => {

    const [authorizedAccess, setAuthorizedAccess] = useState(false);
    const [consentValidated, setConsentValidated] = useState(false);
    const [medicalConsentValidated, setMedicalConsentValidated] = useState(false);

    const handleAccess = change => {
        setAuthorizedAccess(change);
    };

    const closeAll = change => {
        setAuthorizedAccess(change);
        setConsentValidated(change);
        setMedicalConsentValidated(change);
    };

    const handleConsentValidated = change => {
        setConsentValidated(change);
    };

    const handleMedicalConsentValidated = change => {
        setMedicalConsentValidated(change);
    };

    return (
        <React.Fragment>
            {!authorizedAccess && !consentValidated && !medicalConsentValidated && (
                <CollectAccess getAuthorized={handleAccess} />)}
                
            {authorizedAccess && !consentValidated && !medicalConsentValidated && (
                <NewCollectConsent
                closePatientDetails={closeAll}
                handleConsentValidated={handleConsentValidated}/>)}

            {authorizedAccess && consentValidated && !medicalConsentValidated && (
                <NewMedicalConsent
                closePatientDetails={closeAll}
                handleMedicalConsentValidated={handleMedicalConsentValidated} />)}

            {authorizedAccess && consentValidated && medicalConsentValidated && (
                <AcceptedOrRefusedConsent
                consentValidated={consentValidated}
                medicalConsentValidated={medicalConsentValidated}
                closeAll={closeAll} />)}
        </React.Fragment>
    );
}

export default CollectOfConsent;