import React from 'react';
import PropTypes from "prop-types";
import { Box, Grid, Link, Typography, withStyles } from "@material-ui/core";
import signature3 from "../signature3.png";

const ConsentText = props => {

    const { classes, date, firstSignDone, handleFirstSignature } = props;

    return (
        <Grid container direction="row" justify="center" className={classes.text}>
            <Grid item>
                <Typography variant="body1" align="right" gutterBottom className={classes.bold}>
                    <br /> NOTE D’INFORMATION CONCERNANT LE TRAITEMENT DES DONNEES A CARACTERE PERSONNEL
                    </Typography>
            </Grid>
            <Grid item>
                <p>Madame, Monsieur,</p>
                <p>
                    La présente note d’information a pour objet de vous informer
                    conformément à la législation européenne et française  (Règlement Général sur la Protection des Données (RGPD),
                    et loi « Informatiques et libertés » modifiée) actuellement en vigueur.
                </p>
                <p>
                    Dans le cadre de la recherche à laquelle vous participez,
                    un traitement de vos données personnelles est mis en œuvre par la plate-forme régionale Prédice,
                    responsable de traitement, pour permettre d’en analyser les résultats.
                </p>
                <p>
                    A cette fin, les données médicales qui vous concernant
                    et les données relatives à vos habitudes de vie seront transmises au Promoteur
                    ou aux personnes ou partenaires agissant pour son compte en France.
                </p>
                <p>
                    Ces données seront identifiées par un numéro d’enregistrement.
                    Ces données pourront également, dans des conditions assurant leur confidentialité,
                    être transmises aux autorités de santé françaises.
                </p>
                <p>
                    Les données médicales vous concernant pouvant documenter un dossier auprès des autorités compétentes
                    portant sur le complément alimentaire évalué dans cette recherche pourront être transmises à un industriel
                    afin qu’un plus grand nombre de patients puissent bénéficier des résultats de la recherche.
                    Cette transmission sera faite dans les conditions assurant leur confidentialité.
                </p>
                <p>
                    Vos données ne seront conservées que pour une durée strictement nécessaire et proportionnée à la finalité de la recherche.
                    Elles seront conservées dans les systèmes d’information du responsable du traitement,
                    jusqu’à deux ans après la dernière publication des résultats de la recherche.
                </p>
                <p>
                    Vos données seront ensuite archivées selon la règlementation en vigueur.
                </p>
                <p>
                    Le fichier informatique utilisé pour la recherche est mis en œuvre conformément à la législation française et européenne.
                    Vous disposez d’un droit d’accès, de rectification, de limitation et d’opposition
                    au traitement des données couvertes par le secret professionnel utilisées dans le cadre de cette recherche.
                    Ces droits s’exercent auprès du médecin en charge de la recherche qui seul connait votre identité.
                </p>
                <p>
                    En cas de difficulté dans l’exercice de vos droits,
                    vous pouvez saisir le Délégué à la Protection des Données de Prédice à l’adresse suivante :
                    protection.donnees.dsi@predice.fr, qui pourra notamment vous expliquer les voies de recours
                    dont vous disposez auprès de la CNIL. Vous pouvez également exercer votre droit à réclamation directement auprès de la CNIL
                    (pour plus d’information à ce sujet, rendez-vous sur le site <Link href={"www.cnil.fr"} color="secondary">www.cnil.fr</Link>).
                </p>
                {date && (<p>Fait à Lille, le {date},</p>)}
            </Grid>
            {date && (
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item xs md={5} onClick={handleFirstSignature}>
                        <Box pt={1} pb={firstSignDone ? 1.25 : 14} pl={1} border={1} borderRadius={2}>
                            <Grid container direction="column">
                                Signature du patient,
                                {firstSignDone && (<img src={signature3} alt="Signature" className={classes.logo} />)}
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs md={5}>
                        <Box pt={1} pb={14} pl={1} border={1} borderRadius={2}>Signature du représentant légal,</Box>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

ConsentText.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    date: PropTypes.string.isRequired,
};

const styles = () => ({
    text: {
        flex: "1",
        overflow: "auto",
    },
    bold: {
        fontWeight: "bold",
    },
});

export default withStyles(styles)(ConsentText);