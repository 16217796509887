import React from 'react';
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ModalConfirmation = props => {

    const { classes, open, handleModal, handleMedicalConsentValidated } = props;


    return (
        <Dialog
            open={open}
            onClose={() => handleModal(false)}
        >
            <DialogTitle id="modal-title" className={classes.width}>
                <Grid container justify="space-between" alignItems="center">
                    <Typography variant="h6" color="secondary">Confirmer</Typography>
                    <IconButton
                        aria-label="Close"
                        onClick={() => handleModal(false)}
                        className={classes.closeIcon}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                    <Typography>
                    Je confirme avoir pris connaissance des informations qui m’ont été transmises de façon éclairée.
                    </Typography>
                <DialogActions>
                    <Button onClick={() => handleMedicalConsentValidated(true)} variant="contained" color="secondary">
                        Valider
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => handleModal(false)}>
                        Annuler
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

ModalConfirmation.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
    handleMedicalConsentValidated: PropTypes.func,
}

const styles = () => ({
    width: {
        width: "350px",
    },
    closeIcon: {
        position: "absolute",
        top: "8px",
        right: "8px",
    },
});

export default withStyles(styles)(ModalConfirmation);