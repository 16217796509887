import React from 'react';
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const AccessCodeModal = props => {

    const { classes, open, handleModal, modalTitle, label, value, actionButton, addNewConsent } = props;


    return (
        <Dialog
            open={open}
            onClose={() => handleModal(false)}
        >
            <DialogTitle id="modal-title" className={classes.width}>
                <Grid container justify="space-between" alignItems="center">
                    <Typography variant="h6" color="secondary">{modalTitle}</Typography>
                    <IconButton
                        aria-label="Close"
                        onClick={() => handleModal(false)}
                    className={classes.closeIcon}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <TextField
                        id={label}
                        variant="outlined"
                        label={label}
                        value={value}
                        fullWidth
                        margin="normal"
                        disabled={value}
                    />
                </DialogContentText>
            {actionButton && (
                <DialogActions>
          <Button onClick={addNewConsent} variant="contained" color="secondary">
            {actionButton}
          </Button>
        </DialogActions>
            )}
            </DialogContent>
        </Dialog>
    );
}

AccessCodeModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    actionButton: PropTypes.string,
    addNewConsent: PropTypes.func,
}

const styles = () => ({
    width: {
        width: "350px",
    },
    closeIcon: {
        position: "absolute",
        top: "8px",
        right: "8px",
    },
});

export default withStyles(styles)(AccessCodeModal);