import React from 'react';
import PropTypes from "prop-types";
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const consentColumns = {
    DATE: "date",
    EMAIL: "email",
    ETAB: "etab",
    PATIENT: "patient",
    STATUS: "status",
    TYPE: "type",
}

export const getColorByStatus = (status) => {
    switch(status) {
        case "Accepté": return "#357b37";
        case "En attente": return "#0069a0";
        case "Refusé": return "#cc3300";
        case "Révoqué": return "#cc3300";
        default: return "";
    }
}

const PatientConsentsTable = props => {

    const { classes, columns, consents, openConsentActions } = props;

    const getHeaderCell = columnKey => {
        switch (columnKey) {
            case consentColumns.DATE: return <TableCell className={classes.cell}>Date d'envoi</TableCell>;
            case consentColumns.EMAIL: return <TableCell align="left" className={classes.cell}>Email</TableCell>
            case consentColumns.ETAB: return <TableCell align="left" className={classes.cell}>Etablissement</TableCell>
            case consentColumns.PATIENT: return <TableCell align="left" className={classes.cell}>Patient</TableCell>
            case consentColumns.STATUS: return <TableCell align="left" className={classes.cell}>Statut</TableCell>
            case consentColumns.TYPE: return <TableCell align="left" className={classes.cell}>Type de Consentement</TableCell>
            default: return null
        }
    }
    
    const getCell = row => (columnKey) => {
        switch (columnKey) {
            case consentColumns.DATE: return <TableCell component="th" scope="row">{row.date}</TableCell>;
            case consentColumns.EMAIL: return <TableCell align="left">{row.email}</TableCell>
            case consentColumns.ETAB: return <TableCell align="left">{row.hospitalCenter}</TableCell>
            case consentColumns.PATIENT: return <TableCell align="left">{row.patient}</TableCell>
            case consentColumns.STATUS: return <TableCell align="left" style={{color : getColorByStatus(row.status)}}>{row.status}</TableCell>
            case consentColumns.TYPE: return <TableCell align="left">{row.type}</TableCell>
            default: return null
        }
    }

    return (
        <Grid item xs={12}>
            <Paper>
                <Table>
                    <TableHead className={classes.header}>
                        <TableRow>
                            {columns.map(getHeaderCell)}
                            {openConsentActions && (<TableCell align="center" />)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {consents.map(row => (
                            <TableRow key={row.index} className={classes.row}>
                                {columns.map(getCell(row))}
                                {openConsentActions && (
                                    <TableCell align="left">
                                    <IconButton aria-label="Actions" onClick={openConsentActions(row)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Grid>
    );
}

PatientConsentsTable.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    consents: PropTypes.arrayOf(PropTypes.shape({})),
    columns: PropTypes.arrayOf(Object.values(consentColumns)),
    openConsentActions: PropTypes.func.isRequired,
};

PatientConsentsTable.defaultProps = {
    consents: [],
    columns: [],
}

const styles = theme => ({
    header: {
        backgroundColor: theme.palette.secondary.main,
    },
    cell: {
        color: "white",
    },
    row: {
        backgroundColor: "#e7f3f9",
        "&:nth-of-type(even)": {
            backgroundColor: "#b9dcee",
        },
    },
});

export default withStyles(styles)(PatientConsentsTable);
